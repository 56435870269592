@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

body {
  background-color: #18181b;
  font-family: 'Courier New', monospace;
}

p {
  color: #C58FC0;
  text-align: center;
}

img {
  max-width:100%;
  max-height:100%;
}